// Default variables
//-------------------------------------------------------------
var MCMApp = MCMApp || {};
var McmVueMainAppInstance, McmVuePageTitleInstance, McmVueModalInstance;

const McmEventBus = new Vue();


/**
 * Init all main elements
 *
 * @constructor
 */
function McmPlatformMainElementsInit() {

    // Initialize materialize framework
    //-------------------------------------------------------------

    $.material.init();


    // Fix togglebox for IE for hidden inputs
    //-------------------------------------------------------------

    let toggleboxHiddenInput = $('.togglebutton > label > input[type=hidden]');
    if (toggleboxHiddenInput.length > 0) {
        toggleboxHiddenInput.each(function () {
            let $el = $(this);
            $el.parents('.togglebutton').prepend($el.clone());
            $el.remove();
        });
    }



    // Initialize tooltip plugin
    //-------------------------------------------------------------

    $('.has-tooltip').tooltip();


    // Initialize select2 plugin
    //-------------------------------------------------------------

    $('.taggable-select').select2({
        tags: true,
        createTag: function (params) {
            // Don't offset to create a tag if there is no @ symbol
            if (params.term.indexOf('@') === -1) {
                // Return null to disable tag creation
                return null;
            }
            return {
                id: params.term,
                text: params.term
            };
        },
        insertTag: function (data, tag) {
            // Insert the tag at the end of the results
            data.push(tag);
        }
    });


    // Initialize selectric plugin
    //-------------------------------------------------------------

    $('.single-select').selectric({
        maxHeight: 200,
        disableOnMobile: true,
        nativeOnMobile: true
    });


    // Initialize datepicker plugin
    //-------------------------------------------------------------

    $(".datepicker").datepicker({
        "dateFormat": "dd-mm-yy",
        minDate: new Date(),
        onSelect: function (dateText, inst) {
            McmEventBus.$emit('dateChangedOnDatepicker', $(this));
        }
    });


    // Initialize datetimepicker plugin
    //-------------------------------------------------------------

    if ($.datetimepicker) {
        $('.timepicker').datetimepicker({
            format: 'H:i',
            step: 15,
            validateOnBlur: true,
            datepicker: false,
            onChangeDateTime: function (dp, $input) {
                McmEventBus.$emit('dateChangedOnDatepicker', $input);
            }
        });

        $('.datetimepicker-of-touchpoint').datetimepicker({
            format: 'd-m-Y H:i',
            minDate: 0,
            maxDate: moment(moment().add(2, 'years')).format("Y/MM/DD"),
            step: 5,
            validateOnBlur: true,
            yearStart: new Date().getFullYear(),
            yearEnd: new Date().getFullYear() + 2,
            dayOfWeekStart: 1

        });
    }


    // Generic datatables instance
    //-------------------------------------------------------------

    /*
     $('.has-datatables').DataTable({
     ordering: true
     });
     */

}


/**
 * Init masonry on cards
 *
 * @constructor
 */
function McmInitMasonryCardItems() {

    let cardsContainer = $('.cards-container');
    let timer = setTimeout(function () {
        cardsContainer.masonry();
        cardsContainer.masonry('reloadItems');
        cardsContainer.masonry({
            itemSelector: '.cards-item',
            gutter: 30,
            stagger: 30
        });
        cardsContainer.css('opacity', 1);
    }, 500);

    cardsContainer.masonry({
        itemSelector: '.cards-item',
        gutter: 30,
        stagger: 30,
        initLayout: false
    });

    cardsContainer.css('opacity', 0);
}


/**
 * Init html text editor
 *
 * @constructor
 */
function McmInitEditor() {
    if ($.trumbowyg === undefined) {
        return;
    }

    $('.has-content-editor').trumbowyg({
        btns: [
            ['viewHTML', 'formatting', 'removeformat'],
            ['link'],
            'btnGrp-semantic',
            'btnGrp-justify',
            'btnGrp-lists'
        ],
        svgPath: '/components/trumbowyg/dist/ui/icons.svg',
        removeformatPasted: true,
        autogrow: true
    });

}

/**
 * Vue instance initialize
 *
 * @param instance
 * @param instanceVariableName
 */
function vueInstanceInit(instance, instanceVariableName, data) {
    data = data || {};
    if (document.getElementById(instance)) {
        window[instanceVariableName] = new Vue({
            data: data
        });

        window[instanceVariableName].$mount('#' + instance);
    }
}


/**
 * This app-instance is aimed for using vuejs components
 * everywhere instead of separate instances
 * So we can prevent the necessity of nested instances
 *
 * @constructor
 */
function McmInitVueInstances() {


    //app-instance
    vueInstanceInit('app-instance', 'McmVueMainAppInstance');

    //page-title-instance
    vueInstanceInit('page-title-instance', "McmVuePageTitleInstance");
}


/**
 * This is mostly used to initialize 3rd party libraries that works globally
 */
$(function () {

    'use strict';

    // Default variables
    //-------------------------------------------------------------

    const body = $('body');


    // Initialize main elements
    //-------------------------------------------------------------

    new McmPlatformMainElementsInit();


    // Initialize html text editor
    //-------------------------------------------------------------

    new McmInitEditor();

    // Initialize Vue.js instances
    //-------------------------------------------------------------

    new McmInitVueInstances();


    // Initialize inputs for phone numbers
    //-------------------------------------------------------------

    $('.is-phone-number').mask('+99-999-999-9999', {
        autoclear: false
    });


    // Datepickers in form section
    //-------------------------------------------------------------

    $(".datepicker").datepicker({
        "dateFormat": "dd-mm-yy",
        minDate: new Date()
    });


    // Initialize fitVids
    //-------------------------------------------------------------

    $(".video-embed").fitVids();


    // Initialize cards masonry
    //-------------------------------------------------------------

    if (undefined !== window.Masonry) {

        $('.cards-container').masonry({
            itemSelector: '.cards-item',
            gutter: 30,
            stagger: 30
        });
    }


    // Initialize datetime picker
    //-------------------------------------------------------------

    $('.datetimepicker').datetimepicker({
        format: 'd-m-Y H:i:s',
        minDate: 0,
        maxDate: moment(moment().add(2, 'years')).format("Y/MM/DD"),
        step: 30,
        validateOnBlur: true,
        yearStart: new Date().getFullYear(),
        yearEnd: new Date().getFullYear() + 2,
        dayOfWeekStart: 1

    });


    // Initialize datetime picker on timepicker
    //-------------------------------------------------------------
    $('.timepicker').datetimepicker({
        format: 'H:i',
        step: 5,
        validateOnBlur: true,
        datepicker: false
    });


    // Set locale datetime picker
    //-------------------------------------------------------------
    $.datetimepicker.setLocale('en');


    // Initialize eye toggler
    //-------------------------------------------------------------

    body.on('click', '.toggle--modal-helper-info-area', function () {
        $(this).toggleClass('is-opened');
        $('.modal-helper-info-area').slideToggle();
    });

    // Language selection button event
    //-------------------------------------------------------------

    $('.language-selection-buttons .btn').click(function () {
        let lang = $(this).data('lang');
        let $parent = $(this).parents('.multi-language-content-section');

        $('.language-selection-buttons .btn-primary').removeClass('btn-primary');
        $(this).addClass('btn-primary');

        $parent.find('.language-content').addClass('hidden');
        $parent.find('.language-content__' + lang).removeClass('hidden');
    });


    // Hide controls on overlay click
    //-------------------------------------------------------------

    body.on('click', '.mcm-overlay', function () {
        pageTitleComponent.$refs.overlay.toggleControls();
    });

    // Entry alert handler
    //-------------------------------------------------------------
    MCMApp.Forms.ProgramEntryAlerts.init();


});