/**
 * -------------------------------------------------------------------------------
 * This is a helper functions file intended for generic and pure functions
 * that doesn't change the state of application or handles a complex workflow
 * but only handles a small and specific job
 *
 * If you are thinking about adding a new function here think 2-3 more times
 * if this is really good and have high benefits to make a new global function
 * or you are trying to solve a problem in a dirty and lazy way.
 *
 * You may add the function if it fits the criteria and you are ok with the
 * paragraph above.
 *
 * -------------------------------------------------------------------------------
 */
const McmHelpers = {

    notify: {
        success(message) {
            if(typeof message !== 'string') {
                return;
            }

            new Noty({
                type: 'success',
                layout: 'topRight',
                text: message,
                timeout: 3000
            }).show();
        }
    }


};