Vue.component('email-campaigns-of-touchpoint', {
    template: '#email-campaigns-of-touchpoint-template',
    props: ['touchpointId'],
    data: function () {
        return {
            loading: false,
            campaigns: []
        };
    },
    created: function () {
        McmEventBus.$on('touchpointEmailCampaignChanged', () => {
            this.update();
        });
    },
    ready: function () {
        this.update();
    },
    methods: {
        update: function () {
            const url = SiteVariables.urls.touchpointCampaignsController + '/index/' + this.touchpointId;

            console.log(url);
            this.loading = true;
            $.post(url, {}, (response) => {
                this.campaigns = response.campaigns;
                this.loading = false;
            });
        },

        openAddNewModal: function () {

            const data = {
                'action': 'add-to-touchpoint',
                'touchpoint_id': this.touchpointId
            };

            MCMApp.EmailCampaignModalActions.modalAction(data);
        }
    }
});

Vue.component('email-campaign-of-touchpoint', {
    template: '#email-campaign-of-touchpoint-template',
    props: {
        campaign: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            report: false
        };
    },
    computed: {
        status: function () {
            //TODO: if scheduled
            return (this.campaign.is_sent) ? 'Sent' : 'Not sent';
        },
        previewLink(){
            return '/admin/message-templates/mail-templates/preview/'+this.campaign.id;
        }
    },
    ready: function () {
        if (this.campaign.sent_records.length > 0) {
            //this.loadBasicReport();
        }
    },

    methods: {
        previewEmail: function () {
            MCMApp.ModalActions.touchpointMailTemplatePreview(this.campaign.mail_template_id);
        },



        sendCampaign: function () {

            let confirmFirst = confirm('This will start sending emails for this campaign, are you sure?');

            if (!confirmFirst) {
                return false;
            }

            const url = SiteVariables.urls.emailCampaignSenderController + '/index/' + this.campaign.id;
            $.post(url, {}, function (response) {
                McmEventBus.$emit('touchpointEmailCampaignSent', this.campaign.id);
            });
        },

        openEditModal: function () {

            const data = {
                'action': 'edit-on-touchpoint',
                'email_campaign_id': this.campaign.id
            };

            MCMApp.EmailCampaignModalActions.modalAction(data);
        },

        generateBasicReport: function () {
            const url = SiteVariables.urls.genericReportController + '/update-campaign-reports/' + this.campaign.id;
            var self = this;

            this.loading = true;
            $.post(url, {}, (response) => {
                self.loadBasicReport();
            });
        },

        loadBasicReport: function () {
            const url = SiteVariables.urls.genericReportController + '/campaign-report/' + this.campaign.id;

            console.log(url);

            this.loading = true;
            $.post(url, {}, (response) => {
                console.log(response);
                this.report = response;
                this.loading = false;
            });

        }
    }
});

Vue.component('touchpoint-goals-table', {
    template: '#touchpoint-goals-table-template',
    props: ['touchpoint']
});