//All modals should be registered under this object

var MCMApp = MCMApp || {};
MCMApp.Classes = MCMApp.Classes || {};
MCMApp.Modals = MCMApp.Modals || {};

/**
 * Modal Class
 */
MCMApp.Classes.Modal = Backbone.View.extend({
    el: '#general-modal',
    contentUrl: false,
    clearableContent: true,
    events: {'click .close-modal': 'close'},

    /**
     * In Backbone 1.1.0, if you want to access passed options in
     * your view, you will need to save them as follows:
     * @param options
     */
    initialize(options) {
        this.options = options || {};
        this.clearableContent = (undefined !== options.clearableContent) ? options.clearableContent : true;

        if (this.contentUrl) {
            this.loadContent();
        }
    },


    /**
     * This could be dynamic if you have more than one modal
     */
    modalBox() {
        return this.$el;
    },

    /**
     * This could be dynamic if you have more than one modal (Check the name, this is a modal with an e)
     */
    modelBox() {
        return this.$el;
    },

    /**
     * Open modal with no content
     *
     * @returns {MCMApp.Classes.Modal}
     */
    open() {
        this.clearContent();
        this.closeOtherInstances();
        this.modelBox().modal();
        this.modelBox().find('.modal-dialog').removeClass('modal-lg');

        return this;
    },

    /**
     * Open static content
     *
     * @returns {MCMApp.Classes.Modal}
     */
    openStatic() {
        this.clearContent();
        this.closeOtherInstances();
        this.modelBox().modal({
            backdrop: 'static',
            keyboard: false
        });
        this.modelBox().find('.modal-dialog').removeClass('modal-lg');

        return this;
    },

    /**
     * Open large modal
     *
     * @returns {MCMApp.Classes.Modal}
     */
    openLarge() {
        this.clearContent();
        this.closeOtherInstances();
        this.modelBox().modal();
        this.modelBox().find('.modal-dialog').addClass('modal-lg');

        return this;
    },

    /**
     * Open large modal static
     *
     * @returns {MCMApp.Classes.Modal}
     */
    openLargeStatic() {
        this.clearContent();
        this.closeOtherInstances();
        this.modelBox().modal({
            backdrop: 'static',
            keyboard: false
        });
        this.modelBox().find('.modal-dialog').addClass('modal-lg');

        return this;
    },

    /**
     * Open x-large modal static
     *
     * @returns {MCMApp.Classes.Modal}
     */
    openXLargeStatic() {
        this.clearContent();
        this.closeOtherInstances();
        this.modelBox().modal({
            backdrop: 'static',
            keyboard: false
        });
        this.modelBox().find('.modal-dialog').addClass('modal-xl');

        return this;
    },

    /**
     *
     * @param html
     * @returns {MCMApp.Classes.Modal}
     */
    content(html) {
        this.modelBox().find('.modal-body').html(html);
        this.moveTitleToTop();

        return this;
    },
    /**
     *
     * @param html
     * @returns {MCMApp.Classes.Modal}
     */
    headerContent(html) {
        this.modelBox().find('.modal-title').html(html);
        this.moveTitleToTop();

        return this;
    },

    /**
     * Clear content of modal
     */
    clearContent() {
        this.headerContent('');
        $('.modal-title').html(' ');

        //clear size classes
        this.modelBox().find('.modal-dialog').removeClass('modal-lg modal-xl');

        if (this.clearableContent) {
            this.content('<div class="text-center"><span class="">' + document.getElementById('mcm-loader-gif-container').innerHTML + '</span></div>');
        }
    },

    /**
     * hide all other modals
     * @returns {MCMApp.Classes.Modal}
     */
    close() {
        this.modelBox().modal('hide');
        this.clearContent();

        return this;
    },

    /**
     * hide all other modals
     * @param miliseconds
     *
     * @returns {MCMApp.Classes.Modal}
     */
    closeAfter(miliseconds) {
        let closeTimer = setTimeout(() => {
            this.modelBox().modal('hide');
            this.clearContent();
        }, miliseconds);

        return this;
    },

    /**
     *
     * @param contentUrl
     *
     * @returns {MCMApp.Classes.Modal}
     */
    loadContent(contentUrl) {
        $.post(contentUrl, {}, (response) => this.content(response));

        return this;
    },

    /**
     *
     * @param contentUrl
     * @param data
     * @param vueInstance -> This is just a flag passed in the handler, not to be confused with the Vue instance.
     *
     * @returns {MCMApp.Classes.Modal}
     */
    loadContentWithData(contentUrl, data, vueInstance) {
        $.post(contentUrl, data, (response) => {
            this.content(response);

            MCMApp.Modals.appModal.initVueInstanceInModal(vueInstance);
        });

        return this;
    },

    /**
     * Get content with dynamic data
     *
     * @param contentUrl
     * @param data
     *
     * @return {MCMApp.Classes.Modal}
     */
    getContentWithData(contentUrl, data) {
        $.get(contentUrl, data, (response) => this.content(response));

        return this;
    },

    /**
     * closeOtherInstances Method
     */
    closeOtherInstances() {
        _.each(this.Modals, (modal, index) => {
            if (this !== modal) {
                modal.modelBox().modal('hide');
            }
        });
    },

    /**
     * Set modal title, it check the h1 in the .modal-body class.
     * Make sure to hide the h1 always with the hide or hidden class.
     *
     * @returns {boolean}
     */
    moveTitleToTop() {
        const modal = this.modalBox(),
            headerTitle = modal
                .find('.modal-body h1'),
            headerTitleHtml = headerTitle
                .hide()
                .html();
        modal.find('.modal-title').html(headerTitleHtml);
    },


    /**
     * Remove modal from DOM
     */
    destroyModal() {
        $('#app-modal').on('hidden.bs.modal', function (e) {
            let modal = $(this);
            modal.remove();


            console.log(McmVueModalInstance, McmVueModalInstance.$destroy);
            if (McmVueModalInstance.$destroy) {
                McmVueModalInstance.$destroy();
            }
        });
    },


    /**
     * Init vue instance in modal
     *
     * @param vueInstance -> This is just a flag passed in the handler, not to be confused with the Vue instance.
     */
    initVueInstanceInModal(vueInstance){
        if (vueInstance && Array.isArray(vueInstance) && vueInstance[0]) {
            vueInstance[1] = vueInstance[1] || 'app-modal-instance';

            const data = (vueInstance[2] !== undefined) ? vueInstance[2] : {};

            vueInstanceInit(vueInstance[1], 'McmVueModalInstance', data);
        }

        if (_.isObject(vueInstance) && vueInstance.initialize === true) {
            vueInstance.id = vueInstance.id || 'app-modal-instance';

            const data = (vueInstance.data !== undefined) ? vueInstance.data : {};

            vueInstanceInit(vueInstance.id, 'McmVueModalInstance', data);
        }

    }

});


/**
 * TODO: should be in a utility file not class definition file
 * @param action string -> Action where network needs to route to
 * @param data object -> Serialized data object
 * @param size string -> Modal size [lg,sm(default fallback)]
 * @param vueInstance array|object -> Init vue instance to a given modal, two values are passed to the array [{boolean},{#element-id}]
 *
 * @returns {boolean}
 *
 * @private
 */
function _handleModalActions(action, data, size, vueInstance) {
    size = size || null;
    vueInstance = vueInstance || false;

    const url = SiteVariables.urls.ajaxModals + "/" + action;

    switch (size) {
        case 'lg':
            MCMApp.Modals.appModal.openLargeStatic();
            break;
        case 'xl':
            MCMApp.Modals.appModal.openXLargeStatic();
            break;
        case 'lgnoeffect':
            MCMApp.Modals.appModal.openLargeStaticWithoutSideEffects();
            break;
        default:
            MCMApp.Modals.appModal.openStatic();
            break;
    }

    MCMApp.Modals.appModal.loadContentWithData(url, data, vueInstance);

    return false;
}

/**
 * To check side effects of a modal is active
 * Should be set to true after using if it is set to false
 *
 * The aim is preventing refreshing content in close event listeners
 * of modals
 *
 * Creating functions like checkAppModalClosingSideEffects, getAppModalClosingSideEffects
 * to implement a better solution easier
 *
 * TODO: should be in a utility file not class definition file
 * @type {boolean}
 */
var AppModalClosingSideEffects = true;

function checkAppModalClosingSideEffects() {
    return window.AppModalClosingSideEffects;
}

function setAppModalClosingSideEffects(value) {
    if (value === true || value === false) {
        window.AppModalClosingSideEffects = value;
    }
}

function closeAppModal() {
    MCMApp.Modals.appModal.close();
}