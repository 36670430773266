var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};


MCMApp.Templates.ServicePackages = {

    editForms: function (servicePackage, checkedServices, serviceTypesList) {

       return new Vue({
            el: '#service-package-form',
            data: {
                servicePackage: servicePackage,
            }

        });
    },

    editServices: function (servicePackage, checkedServices, serviceTypesList) {
        return new Vue({
            el: '#service-types-page',
            data: {
                servicePackage: servicePackage,
                serviceTypes: serviceTypesList,
                checkedServices: checkedServices
            },

            created: function () {
                this.refreshList();
            },

            methods: {
                refreshList: function () {
                    var url = SiteVariables.urls.serviceTypesController + '/index/' + this.servicePackage.broadcast_setting_id;
                    $.get(url, {}, function (response) {
                        serviceTypesList.list = JSON.parse(response);
                    });

                },


            }

        });

    },

    compare: function (servicePackage, checkedServices, serviceTypesList) {
        return new Vue({
            el: '#service-compare',
            data: {
                packageId: false,
                servicePackage: servicePackage,
                serviceTypes: serviceTypesList,
                checkedServices: checkedServices
            },

            created: function () {
                this.refreshList();
            },

            methods: {
                refreshList: function () {
                    const url = SiteVariables.urls.serviceTypesController + '/index/' + this.servicePackage.broadcast_setting_id;

                    $.get(url, {}, function (response) {
                        serviceTypesList.list = JSON.parse(response);
                    });

                },

            }

        });
    }
};