Vue.component('target-list', {
    template: '#target-list-template',
    props: {
        sourceUrl: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            error: "",
            flash: "",

            listing: false,
            filter: {}
        };
    },
    watch: {
        listing(listing) {
            this.filter = listing.columns.reduce((p, c) => {
                p[c.key] = "";
                return p;
            }, {});
        }
    },
    computed: {
        filtered() {
            return this.listing.hcp_metas.filter((hcp) => {
                let valid = true;

                Object.keys(hcp.columns).forEach((colKey) => {
                    if (!this.like(
                        this.filter[colKey],
                        hcp.columns[colKey].value)) valid = false;
                });
                return valid;
            });
        },
        exportData() {
            if (!this.filtered) return null;

            let header = this.listing.columns.map(c => c.readable);

            let data = this.filtered.map(hcpMeta => {
                return this.listing.columns.map(c => {
                    return hcpMeta.columns[c.key] ?
                        hcpMeta.columns[c.key].value :
                        " - ";
                });
            });

            let table = [header].concat(data);

            return table;
        }
    },
    methods: {
        setFlash(flash) {
            this.flash = flash;
            setTimeout(() => {
                this.flash = false;
            }, 5000);
            
        },
        updateData() {
            $.get(this.sourceUrl, {}, (response) => {
                this.listing = response.listing;
            });
        },
        updateListing(listing) {
            this.listing = listing;
        },
        setError(error) {
            this.error = error;
        },
        // Helper function for matching filter data. Acts similar to SQL's LIKE.
        like(pattern, sentence) {
            if (!(typeof sentence === 'string' && typeof pattern === 'string')) return true;
            return sentence.toLowerCase().includes(pattern.toLowerCase());
        },
        datetimeFile() {
            return moment().format('DDMMYYHHmmss');
        },
        exportFile(extension) {
            let sheet = XLSX.utils.aoa_to_sheet(this.exportData);
            let book = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(book, sheet, "Report");

            let filename = "mcm_report-" + this.datetimeFile() + "." + extension;
            XLSX.writeFile(book, filename);
        }
    },
    ready: function () {
        this.updateData();
    },

    components: {
        'target-list-attach': {
            template: '#target-list-attach-template',
            props: {
                listing: Object
            },
            data: function () {
                return {
                    reuploading: false,
                    saving: false,

                    matchedCols: {},
                    customCols: {},

                    mcmColumns: {}
                };
            },
            methods: {
                save(e) {
                    if(this.saving) return;
                    this.saving = true;

                    e.preventDefault();
                    const action = e.target.getAttribute('action');

                    var payload = {};

                    for (let matchedCol in this.matchedCols) {
                        let colObj = this.matchedCols[matchedCol];

                        payload[matchedCol] = colObj;
                        //custom columns added by column name itself
                        payload[matchedCol].readable = (this.mcmColumns[colObj.mcmKey])?this.mcmColumns[colObj.mcmKey]:colObj.mcmKey;


                    }

                    $.ajax({
                        method: 'POST',
                        url: action,
                        dataType: 'json',
                        contentType: 'application/json',
                        data: JSON.stringify(payload),
                        success: (response) => {
                            this.$emit('update-listing', response.listing);
                            this.$emit('flash', "Updated listing.");
                        },
                        error: (response) => {
                            this.$emit('error', response.responseJSON.message);
                        },
                        complete: () => {
                            this.saving = false;
                        }
                    });
                },
                reupload(e) {
                    if(this.reuploading) return;
                    this.reuploading = true;

                    e.preventDefault();
                    const action = e.target.getAttribute('action');

                    let formData = new FormData(e.target);

                    $.ajax({
                        method: 'POST',
                        url: action,
                        data: formData,
                        contentType: false,
                        processData: false,
                        success: (data) => {
                            if(data.success) {
                                this.$emit('update-listing', data.listing);
                            }

                            this.reuploading = false;
                        }
                    });
                },
                watch : {
                    customCols(val,oldVal){

                        console.log(val,oldVal);
                    },
                    listing() {
                        // Initialize matchedCols & customCol with data from file (listing.columns)
                        for (var fileCol in this.listing.columns) {
                            if (this.listing.columns.hasOwnProperty(fileCol)) {
                                var fileColKey = this.listing.columns[fileCol].key;

                                this.matchedCols[fileColKey] = {
                                    mcmKey: "0"
                                };
                                this.customCols[fileColKey] = false;
                            }
                        }
                    }
                },
                toggleCustomCol(e) {
                    const key = e.target.getAttribute('value');
                    //checked means custom value should be visible
                    const isChecked = e.target.checked;

                    let customCols = this.customCols;
                    customCols[key] = isChecked;
                    this.customCols = JSON.parse(JSON.stringify(customCols));

                    let matchedCols = this.matchedCols;
                    matchedCols[key] = {mcmKey:(isChecked === true) ? "" : "0"};
                    this.matchedCols = JSON.parse(JSON.stringify(matchedCols));
                }
            },
            created: function () {
                this.mcmColumns = this.listing.mcm_columns;
            },
            ready() {
                McmPlatformMainElementsInit();
                Vue.nextTick(() => McmPlatformMainElementsInit());
            }
        }
    }
});
