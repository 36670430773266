var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.Touchpoints = MCMApp.Templates.Touchpoints || {};

MCMApp.Templates.Touchpoints.EventProgram = {
    init: function (program) {
        return new Vue({
            el: '#touchpoint-program',
            data: {
                hours: "",
                content: "",
                subtitle: "",
                starts_at: "",
                ends_at: "",
                calendar: false,
                entry_type: "default",
                programId: program.id,
                program: program,
                uploader: "",
                approval: "",
                video: "",
                presentation_id: "",
                itemIsReadyForEdit: false,
                currentEntryId: "",
            },
            ready: function () {
                McmPlatformMainElementsInit();
                this.uploadPresentationInit();
            },
            watch: {
                calendar: function (val, oldVal) {
                    this.$nextTick(() => {
                        McmPlatformMainElementsInit();
                    });
                }
            },
            compiled: function () {
                const $listEl = $("#touchpoint-program-list");
                $listEl.sortable({
                    update: (event, ui) => {
                        this.updateOrder();
                    }
                });
                $listEl.disableSelection();
            },
            computed: {
                isDefault: function () {
                    return (this.entry_type === 'default');
                }
            },
            methods: {
                addToList: function (event) {
                    if (this.content.length === 0 && this.hours.length === 0) {
                        alert('You must at least fill the content or hours');
                        return false;
                    }

                    var starts_at = moment(this.starts_at, 'DD-MM-YYYY HH:mm');
                    var amsterdam_starts_at = starts_at.clone().tz("Europe/Amsterdam").format('YYYY-MM-DD HH:mm');


                    var ends_at = moment(this.ends_at, 'DD-MM-YYYY HH:mm');
                    var amsterdam_ends_at = ends_at.clone().tz("Europe/Amsterdam").format('YYYY-MM-DD HH:mm');

                    let data = {
                        hours: this.hours,
                        content: this.content,
                        subtitle: this.subtitle,
                        program_id: this.programId,
                        entry_type: this.entry_type,
                        calendar: (this.calendar) ? 1 : 0,
                        starts_at: amsterdam_starts_at,
                        ends_at: amsterdam_ends_at,
                        approval: this.approval,
                        video: this.video,
                        presentation_id: this.presentation_id
                    };

                    const url = SiteVariables.urls.programEntriesController + '/add';

                    $.post(url, data, (response) => {
                        this.refreshProgram();
                    });
                },

                refreshProgram: function () {

                    const url = SiteVariables.urls.programController + '/view/' + this.programId;
                    $.get(url, {}, (response) => {
                        this.program = (response.program);
                        this.resetInputs();
                    });
                },

                makeItemEditable: function (itemId) {
                    const url = SiteVariables.urls.programEntriesController + '/view/' + itemId;
                    $.get(url, {}, (response) => {
                        var currentEntry = (response.programEntry);
                        this.currentEntryId = currentEntry.id;
                        this.hours = currentEntry.hours;
                        this.content = currentEntry.content;
                        this.subtitle = currentEntry.subtitle;
                        this.calendar = currentEntry.calendar;
                        // this.starts_at = moment(currentEntry.starts_at).format('Y-m-d HH:mm') ;
                        // this.ends_at = moment(currentEntry.ends_at).format('Y-m-d HH:mm') ;
                        this.starts_at = currentEntry.starts_at;
                        this.ends_at = currentEntry.ends_at;
                        this.approval = currentEntry.approval;
                        this.video = currentEntry.video;
                        this.presentation_id = currentEntry.presentation_id;
                        if (currentEntry.file) {
                            $('#filelist').html("<p>" + currentEntry.file.name + "</p>");
                        }
                    });

                    this.itemIsReadyForEdit = true;
                },

                editItem: function () {
                    const url = SiteVariables.urls.programEntriesController + '/edit/' + this.currentEntryId;

                    if (this.content.length === 0 && this.hours.length === 0) {
                        alert('You must at least fill the content or hours');
                        return false;
                    }

                    var starts_at = moment(this.starts_at, 'DD-MM-YYYY HH:mm');
                    var amsterdam_starts_at = starts_at.clone().tz("Europe/Amsterdam").format('YYYY-MM-DD HH:mm');


                    var ends_at = moment(this.ends_at, 'DD-MM-YYYY HH:mm');
                    var amsterdam_ends_at = ends_at.clone().tz("Europe/Amsterdam").format('YYYY-MM-DD HH:mm');

                    let data = {
                        hours: this.hours,
                        content: this.content,
                        subtitle: this.subtitle,
                        program_id: this.programId,
                        entry_type: this.entry_type,
                        calendar: (this.calendar) ? 1 : 0,
                        starts_at: amsterdam_starts_at,
                        ends_at: amsterdam_ends_at,
                        approval: this.approval,
                        video: this.video,
                        presentation_id: this.presentation_id
                    };

                    $.post(url, data, (response) => {
                        this.refreshProgram();
                    });

                    this.itemIsReadyForEdit = false;

                },

                deleteItem: function (itemId) {
                    const url = SiteVariables.urls.programEntriesController + '/delete/' + itemId;
                    let confirmation = confirm('Delete this entry?');
                    if (!confirmation) {
                        return false;
                    }
                    $.post(url, {}, (response) => {
                        this.refreshProgram();
                    });
                },


                updateOrder: function () {
                    const values = $('.entry-order-input').serializeArray();

                    const url = SiteVariables.urls.programController + '/edit/' + this.program.id;

                    const data = {
                        entry_order_update: values
                    };

                    $.post(url, data, (response) => {
                        this.refreshProgram();
                    });

                },

                resetInputs: function () {
                    this.hours = "";
                    this.content = "";
                    this.subtitle = "";
                    this.calendar = false;
                    this.starts_at = "";
                    this.ends_at = "";
                    this.approval = "";
                    this.video = "";
                    this.presentation_id = "";
                    this.cleanUploader();
                },

                cleanUploader: function () {
                    $('#filelist').html("");
                    $('.progress').addClass('hidden');
                },

                uploadPresentationInit: function () {
                    var self = this;
                    self.uploader = new plupload.Uploader({
                        runtimes: 'html5,html4',

                        browse_button: 'pickfiles',
                        container: document.getElementById('uploader'),
                        url: SiteVariables.urls.programEntriesController + '/presentation-upload',
                        flash_swf_url: '../js/Moxie.swf',
                        silverlight_xap_url: '../js/Moxie.xap',
                        file_data_name: "path",
                        multi_selection: false,
                        filters: {
                            max_file_size: '10mb',
                            mime_types: [
                                {title: "Presentation files", extensions: "pptx,ppt,pdf"},
                            ]
                        },
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        init: {
                            PostInit: function () {
                                document.getElementById('filelist').innerHTML = '';
                            },

                            FilesAdded: function (up, files) {
                                up.files.splice(0, up.files.length - 1);
                                plupload.each(files, function (file) {
                                    document.getElementById('filelist').innerHTML += '<div id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></div>';
                                });
                                up.start();
                            },

                            UploadProgress: function (up, file) {
                                //document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
                                $('.progress').removeClass('hidden');
                                $('.progress-bar').css('width', file.percent + '%').text(file.percent + '%');

                            },

                            FileUploaded: function (up, file, response) {
                                var presentation = $.parseJSON(response.response);
                                self.presentation_id = presentation.id;
                            },

                            Error: function (up, err) {
                                document.getElementById('console').innerHTML += "\nError #" + err.code + ": " + err.message;
                            }
                        }
                    });

                    self.uploader.init();
                },
            }
        });
    },

    /**
     * called on Template/Touchpoints/easyflowedit/sections/program.ctp
     */
    programView: function (touchpointProgram) {
        return new Vue({
            el: '#touchpoint-program-details',
            data: {
                programId: touchpointProgram.id,
                program: touchpointProgram,
            },

            ready() {
                //TODO: not nice way, just trying

                this.refreshInterval = setInterval(() => this.refreshProgram(), 10000);


                $('#touchpoint-program-details').removeClass('hidden');

            },
            methods: {

                refreshProgram: function () {
                    var url = SiteVariables.urls.programController + '/view/' + this.programId;
                    $.get(url, {},  (response) => {
                        this.program = JSON.parse(response);
                    });
                },


            }
        });
    }
};