var MCMApp = MCMApp || {};


/**
 * Define modal actions
 */
MCMApp.ModalActions = {

    /**
     * Init all listeners
     */
    init() {

        // Speaker detail modal listener
        //------------------------------------------------------------------

        this.speakerDetailModalListener();


        // Video detail modal listener
        //------------------------------------------------------------------

        this.videoDetailModalListener();


        // Page detail modal listener
        //------------------------------------------------------------------

        this.pageDetailModalListener();


        // Touchpoint register modal listener
        //------------------------------------------------------------------

        this.touchpointRegisterModalListener();


        // Touchpoint login modal listener
        //------------------------------------------------------------------

        this.touchpointLoginModalListener();


        // Pagination in modal listener
        //------------------------------------------------------------------

        this.paginationInModalListener();
    },


    /**
     * speakerDetailModalListener Method
     *
     * @return {Boolean}
     */
    speakerDetailModalListener() {
        const speakerDetailModalTrigger = $('.open-speaker-detail-modal');

        if (speakerDetailModalTrigger.length > 0) {
            speakerDetailModalTrigger.click(function () {
                const data = {
                    speaker_id: $(this).data('id'),
                    action: 'show-detail'
                };

                _handleModalActions('speakers', data, 'lg');

                return true;
            });
        }

        return false;
    },


    /**
     * speakerEditModalListener Method
     *
     * @param speakerId
     * @return {Boolean}
     */
    speakerEditModalListener(speakerId) {
        const data = {
            speaker_id: speakerId,
            action: 'show-edit',
            redirect: window.location.href
        };

        _handleModalActions('speakers', data, 'lg');

        return true;
    },


    /**
     * videoDetailModalListener Method
     *
     * @return {Boolean}
     */
    videoDetailModalListener() {
        const videoDetailModalTrigger = $('.open-video-detail-modal');

        if (videoDetailModalTrigger.length > 0) {
            videoDetailModalTrigger.click(function () {
                const data = {
                    video_id: $(this).data('id'),
                    action: 'show-detail'
                };

                _handleModalActions('videos', data);

                return true;
            });
        }

        return false;
    },


    /**
     * pageDetailModalListener Method
     *
     * Open content of page in a modal box
     *
     * @return {Boolean}
     */
    pageDetailModalListener() {
        if ($('.open-page-detail-modal').length) {
            $('body').on('click', '.open-page-detail-modal', function () {
                const data = {
                    page_id: $(this).data('id'),
                    action: 'show-detail'
                };

                _handleModalActions('pages', data, 'lg');

                return true;
            });

            $('body').on('open-landing-page-modal', function (event, pageId) {
                const data = {
                    page_id: pageId,
                    action: 'show-detail'
                };

                _handleModalActions('pages', data, 'lg');

                return true;
            });
        }

        return false;
    },


    /**
     * paginationInModalListener Method
     *
     * Listen and load content for paginations done in modal
     *
     * @return {Boolean}
     */
    paginationInModalListener() {
        $('.modal').on('click', '.paginator--in-modal a', function (e) {
            e.preventDefault();

            const url = $(this).attr('href');

            MCMApp.Modals.appModal.loadContent(url);

            return true;
        });

        return false;
    },


    /**
     * openTouchpointRegisterModal Method
     *
     * @param touchpointId
     * @param createdBy
     *
     * @return {Boolean}
     */
    openTouchpointRegisterModal(touchpointId, createdBy) {
        const data = {
            created_by: createdBy,
            touchpoint_id: touchpointId,
            action: 'register-form'
        };

        _handleModalActions('touchpoint-public', data);

        return true;
    },


    /**
     * openProjectCreateScreen Method
     *
     * @param areaId
     * @return {boolean}
     */
    openProjectCreateScreen(areaId) {
        const data = {
            area_id: areaId,
            action: 'create-project-screen'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * createSingleProject Method
     *
     * @param areaId
     * @returns {boolean}
     */
    createSingleProject(areaId) {
        const data = {
            area_id: areaId,
            action: 'create-single-project'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * createListing Method
     *
     * @param areaId
     * @param projectId
     * @returns {boolean}
     */
    createListing(areaId, projectId, touchpointId) {
        const data = {
            area_id: areaId,
            project_id: projectId,
            touchpoint_id: touchpointId,
            action: 'create-listing'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * addTouchpoint Method
     *
     * @param projectId
     * @returns {boolean}
     */
    addTouchpoint(projectId) {
        const data = {
            project_id: projectId,
            action: 'add-touchpoint'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },

    /**
     * projectStakeholders Method
     *
     * @param projectId
     * @returns {boolean}
     */
    projectStakeholders(projectId) {
        const data = {
            project_id: projectId,
            action: 'project-stakeholders'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * touchpointGeneralDetails Method
     *
     * @param touchpointId
     * @returns {boolean}
     */
    touchpointGeneralDetails(touchpointId) {
        const data = {
            touchpoint_id: touchpointId,
            action: 'touchpoint-edit-general-info'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * touchpointId Method
     *
     * @param touchpointId
     * @returns {boolean}
     */
    touchpointDelete(touchpointId) {
        const data = {
            touchpoint_id: touchpointId,
            action: 'touchpoint-delete'
        };

        _handleModalActions('touchpoint-flow', data);

        return true;
    },


    /**
     * touchpointEasyFlow Method
     *
     * @param touchpointId
     * @returns {boolean}
     */
    touchpointEasyFlow: function (touchpointId) {
        const data = {
            touchpoint_id: touchpointId,
            action: 'touchpoint-easy-flow'
        };

        _handleModalActions('touchpoint-flow', data, 'lg');

        return true;
    },


    /**
     * touchpointMailTemplatePreview Method
     *
     * @param mailTemplateId
     * @returns {boolean}
     */
    touchpointMailTemplatePreview: function (mailTemplateId) {
        const data = {
            template_id: mailTemplateId,
            action: 'touchpoint-mail-template-preview'
        };

        _handleModalActions('touchpoint-flow', data, 'lg');

        return true;
    },


    /**
     * openProgramManager Method
     *
     * @param touchpointId
     * @returns {boolean}
     */
    openProgramManager(touchpointId) {
        const data = {
            touchpoint_id: touchpointId,
            action: 'touchpoint-program-manager'
        };

        _handleModalActions('touchpoint-flow', data, 'lg');

        return true;
    },


    /**
     * openServicePackageDetails Method
     *
     * @param servicePackageId
     * @returns {boolean}
     */
    openServicePackageDetails(servicePackageId) {
        const data = {
            service_package_id: servicePackageId,
            action: 'service-package-details'
        };

        _handleModalActions('service-actions', data, 'lg');

        return true;
    },


    /**
     * openApproveBudget Method
     *
     * @param touchpointId
     * @returns {boolean}
     */
    openApproveBudget(touchpointId) {
        const data = {
            touchpoint_id: touchpointId,
            action: 'approve-budget'
        };

        _handleModalActions('touchpoint-flow', data, 'lg');

        return true;
    },


    /**
     * touchpointRegisterModalListener Method
     *
     * Open register modal for form
     *
     * @return {boolean}
     */
    touchpointRegisterModalListener() {
        const registerModalTrigger = $('.open-tp-register-detail-modal');


        if (registerModalTrigger.length > 0) {
            const hashData = registerModalTrigger.data('hash');

            console.log('has',registerModalTrigger,hashData);
            registerModalTrigger.click(function () {
                const data = {
                    created_by: false,
                    touchpoint_id: $(this).data('id'),
                    hash: (hashData) ? hashData : '',
                    action: 'register-form'
                };

                _handleModalActions('touchpoint-public', data);

                return true;
            });
        }

        return false;
    },


    /**
     * touchpointLoginModalListener Method
     *
     * @return {boolean}
     */
    touchpointLoginModalListener() {
        const loginModalTrigger = $('.open-tp-login-modal');

        if (loginModalTrigger.length > 0) {
            loginModalTrigger.click(function () {
                const data = {
                    created_by: false,
                    touchpoint_id: $(this).data('id'),
                    action: 'login-form'
                };

                _handleModalActions('touchpoint-public', data, null, [true]);

                return true;
            });
        }

        return false;
    },

    openAdboardGroupCalendarModal(adboardGroupId) {
        _handleModalActions('adboardCalendar', {action: 'open-calendar', adboardGroupId: adboardGroupId}, 'xl', {
            initialize: true,
            'id': 'app-modal-instance',
            'data': {
                adboardGroupId: adboardGroupId
            }
        });
    }
};
