// Meetingpoint survey actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

/**
 *
 * @type {{editForm: MCMApp.EmailCampaignFormTemplates.editForm}}
 */
MCMApp.EmailCampaignFormTemplates = {
    editForm: function () {
        return new Vue({
            el: '#edit-on-touchpoint-form',
            ready: function () {
                McmPlatformMainElementsInit();
                this.toggleDescriptionsIcs();
            },
            methods: {
                submitForm: function (e) {
                    const $form = $(e.currentTarget);
                    const formData = $form.serialize();
                    const url = $form.attr('action');

                    $.post(url, formData, (response) => {
                        McmEventBus.$emit('touchpointEmailCampaignChanged');
                        MCMApp.Modals.appModal.close();
                    });
                },
                toggleDescriptionsIcs: function () {
                    const icsCheckBox = $('#ics-file-send-emails');
                    const icsContent = $('.ics-email-campaigns');

                    if (icsCheckBox.prop('checked')) {
                        icsContent.removeClass('hide');
                    } else {
                        icsContent.addClass('hide');
                    }
                },
                checkToggleIcs: function () {
                    this.toggleDescriptionsIcs();
                }
            }
        });
    }
};